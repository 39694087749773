import * as actionTypes from '../../action-types/modal-action-types/modal-action-types';
const initialState = {
    modalData: [],
    data: [],
    modalType: null

}
const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SIGNATURE_MODAL_TYPE:
            return { ...state, modalType: action.payload }
        case actionTypes.SET_REMOVE_FEILD_MODAL_DATA:
            return { ...state, modalData: action.payload }
        case actionTypes.SET_DELETE_MODAL_DATA:
            return { ...state, data: action.payload }
        default:
            return state
    }
}
export default modalReducer