import * as actionTypes from '../action-types/musician-transaction-types'
const initialState = {
    transactions: []
}
const transActionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MUSICIAN_TRANSACTIONS:
            return { ...state, transactions: action.payload.allTransActions }
        default:
            return state;
    }


}
export default transActionReducer