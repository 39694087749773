import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Spinner from "./components/layouts/Spinner";
import PrivateRoute from "./components/sections/auth/PrivateRoute";
import { roles } from "./data/roles-permission/roles";
import ReactGa from 'react-ga';
import TagManager from 'react-gtm-module';
import { getUserInfo } from "./helpers/comman_helpers";
// Admin
const Profile = lazy(() => import("./components/pages/Profile"))
const EmailVerification = lazy(() => import("./components/pages/EmailVerification"))

const Maintenance = lazy(() => import("./components/pages/Maintenance"));
const Dashboard = lazy(() => import("./components/pages/Dashboard"));
const Musicians = lazy(() => import("./components/pages/Musicians"));
const GuestPartialMusician = lazy(() => import("./components/pages/GuestPartialMusician"));
// const NonUnionMember = lazy(() => import("./components/pages/nonunionmember/NonUnionMember"));
// const NonUnionMemberForms = lazy(() => import("./components/pages/nonunionmember/NonUnionMemberForms"));
const MusicianNonUnionMember = lazy(() => import("./components/pages/masters/MusicianNonUnionMember"));
const Login = lazy(() => import("./components/pages/Auth/Login"));
const Logout = lazy(() => import("./components/pages/Auth/Login"));
const Forgotpassword = lazy(() => import("./components/pages/Auth/Forgotpassword"));
const Resetpassword = lazy(() => import("./components/pages/Auth/Resetpassword"));
const ChangeTempPassword = lazy(() => import("./components/pages/Auth/ChangeTempPassword"));
const TwoFactorAuthentication = lazy(() => import("./components/pages/Auth/TwoFactorAuthentication"));
const ForgotUserName = lazy(() => import("./components/pages/Auth/ForgotUserName"));
const VerifyEmailContent = lazy(() => import("./components/sections/auth/VerifyEmailContent"));
const UserNameUpdateContent = lazy(() => import("./components/sections/auth/UserNameUpdateContent"));
// Jobs
const Bforms = lazy(() => import("./components/pages/jobs/Bforms"));
const CreateJobs = lazy(() => import("./components/pages/create-jobs/CreateJobs"));
const Jobs = lazy(() => import("./components/pages/jobs/Job"));
const MJobs = lazy(() => import("./components/pages/jobs-musician/Job"));
const MusicianJobs = lazy(() => import("./components/pages/jobs/MusicianJobs"));
const MusicianTransactions = lazy(() => import("./components/pages/jobs/MusicianTransactions"));
const InterimStatement = lazy(() => import("./components/pages/InterimStatement"));
const MembershipPayment = lazy(() => import("./components/pages/MembershipPayment"));
const DonationPayment = lazy(() => import("./components/pages/MembershipPayment"));
const DirectorySearch = lazy(() => import("./components/pages/DirectorySearch"));
const PaymentResponse = lazy(() => import("./components/pages/PaymentResponse"));

const WorkduePayment = lazy(() => import("./components/pages/WorkduePayment"));
// transactions
const Transactions = lazy(() => import("./components/pages/transactions/Transactions"));

const PrintCreditCardTransaction = lazy(() => import("./components/sections/jobs-and-transactions/Transaction/PrintCreditCardTransaction"));

// transactions
const Reports = lazy(() => import("./components/pages/reports/Reports"));

// pdf
const PDF = lazy(() => import("./components/pages/jobs/PDF"));

//hearing-board
const HearingBoard = lazy(() => import("./components/pages/hearing-board/HearingBoard"));
const HearingBoardStatement = lazy(() => import("./components/pages/hearingboard-statements/HearingBoardStatement"))

const HbReminder = lazy(() => import("./components/pages/hb-reminder/HbReminder"));

//taft-hartleys
const TaftHartley = lazy(() => import("./components/pages/taft-hartley/TaftHartley"));
const MusicianLetter = lazy(() => import("./components/pages/taft-hartley/MusicianLetter"));
const EmployerLetter = lazy(() => import("./components/pages/taft-hartley/EmployerLetter"));
const LeaderLetter = lazy(() => import("./components/pages/taft-hartley/LeaderLetter"));
const MusicianPastJobs = lazy(() => import("./components/pages/taft-hartley/MusicianPastJobs"));

// Contracts
const ContractForms = lazy(() => import("./components/pages/contracts/ContractForms"));
const Contract = lazy(() => import("./components/pages/contracts/Contract"));

// Musician
const Signup = lazy(() => import("./components/pages/Auth/Signup"));
const loginWithToken = lazy(() => import("./components/pages/Auth/loginWithToken"));
const SignupWithToken = lazy(() => import("./components/pages/Auth/SignupWithToken"));
const SignupWithTokenContent = lazy(() => import("./components/sections/auth/SignupWithTokenContent"));
const MusicianProfile = lazy(() => import("./components/musician/pages/MusicianDashboard"));
const MusicianStatemants = lazy(() => import("./components/sections/musician-statements/Content"));
const MusicianRegistrationUsingLink = lazy(() => import("./components/pages/musician-registration/MusicianRegistrationUsingLink"));
const Musicianregistration = lazy(() => import("./components/pages/masters/Musicianregistration"));

// MusicianOnlyProfileSection
const MusicianOnlyProfile = lazy(() => import("./components/pages/masters/MusicianOnlyProfile"));
const MusicianPrfStatemants = lazy(() => import("./components/sections/musician-statements/MusiPrfContent"));
const MusicianPrfDocuments = lazy(() => import("./components/sections/documents/MusiPrfContent"));
const MusicianPrfTransactions = lazy(() => import("./components/pages/jobs/MusicianPrfTransactions"));
const MusicianPrfInterimStatement = lazy(() => import("./components/pages/MusiPrfInterimStatement"));
const MusicianPrfJobs = lazy(() => import("./components/pages/jobs/MusicianJobs"));
const JobsDetails = lazy(() => import("./components/pages/musi-jobs-details/MusiciansJobDetails"));
const MusicianPrfMembershipPayment = lazy(() => import("./components/pages/MusicianPrfMembershipPayment"));
const MusicianPrfDonationPayment = lazy(() => import("./components/pages/MusicianPrfMembershipPayment"));
const MusicianPrfWorkduePayment = lazy(() => import("./components/pages/MusicianPrfWorkduePayment"));
const MusicianPaymentResponse = lazy(() => import("./components/pages/MusicianPaymentResponse"));

// Documents 
const Documents = lazy(() => import("./components/sections/documents/Content"));

//fed-reports
const FedRerport = lazy(() => import("./components/pages/fed-report/FedReport"));

//Custom-Api
const CustomApi = lazy(() => import("./components/pages/custom-apis/CustomApi"));

//WeeklyReport
const WeeklyReport = lazy(() => import("./components/sections/weekly-report/Content"));

//RehearsalRoom
const RehearsalRoom = lazy(() => import("./components/sections/rehearsal-rooms/Content"));

//monthly-invoices
const MonthlyInvoice = lazy(() => import("./components/pages/monthly-invoice/MonthlyInvoice"));

{/* Masters */ }
const User = lazy(() => import("./components/pages/masters/User"));
const Userrole = lazy(() => import("./components/pages/masters/Usertype"));
const Phonetype = lazy(() => import("./components/pages/masters/Phonetype"));
const Relation = lazy(() => import("./components/pages/masters/Relation"));
const Instrument = lazy(() => import("./components/pages/masters/Instrument"));
const Country = lazy(() => import("./components/pages/masters/Country"));
const State = lazy(() => import("./components/pages/masters/State"));
const City = lazy(() => import("./components/pages/masters/City"));
const Instrumentroles = lazy(() => import("./components/pages/masters/Instrumentroles"));
const Termsandcondition = lazy(() => import("./components/pages/masters/Termsandconditions"));
const Musicianstatus = lazy(() => import("./components/pages/masters/Musicianstatus"));
const Webpermissions = lazy(() => import("./components/pages/masters/Webpermissions"));
const Bankdetails = lazy(() => import("./components/pages/masters/BankDetails"));
const Notfound = lazy(() => import("./components/pages/Notfound"));
const UnAuthorized = lazy(() => import("./components/pages/UnAuthorized"));
const Employers = lazy(() => import("./components/pages/masters/Employer"));
const EmployerDetails = lazy(() => import("./components/pages/masters/EmployerDetails"));
const Settings = lazy(() => import("./components/pages/masters/Settings"));
const MembershipDuration = lazy(() => import("./components/pages/masters/MembershipDuration"));
const LateFees = lazy(() => import("./components/pages/masters/LateFees"));
const PaymentType = lazy(() => import("./components/pages/masters/PaymentType"));
const EngagementCategory = lazy(() => import("./components/pages/masters/EngagementCategory"));
const ScaleHeader = lazy(() => import("./components/pages/masters/ScaleHeader"));
const ScaleHeaderDetails = lazy(() => import("./components/pages/masters/ScaleHeaderDetails"));
const Signatory = lazy(() => import("./components/pages/masters/Signatory"));
const DocumentCategory = lazy(() => import("./components/pages/masters/DocumentCategory"));
const DocumentUpload = lazy(() => import("./components/pages/masters/DocumentUpload"));
const StatusWisePermission = lazy(() => import("./components/pages/masters/StatusWisePermission"));
const MembershipPermission = lazy(() => import("./components/pages/masters/Membership-Permission"));
const MembStatusPermissions = lazy(() => import("./components/pages/masters/MembStatusPermissions"));
const EmailTemplate = lazy(() => import("./components/pages/masters/EmailTemplate"));
const Overture = lazy(() => import("./components/pages/masters/Overture"));
const ReportMaster = lazy(() => import("./components/pages/masters/ReportMaster"));
const EngagementSubCategory = lazy(() => import("./components/pages/masters/EngagementSubCategory"));
const PrintTemplate = lazy(() => import("./components/pages/masters/PrintTemplate"));
const PrintPreview = lazy(() => import("./components/sections/masters/emailtemplate/PrintPreview"));
const PostingDateUpdate = lazy(() => import("./components/sections/postingdateupdate/Content"));
const MembershipBreakdown = lazy(() => import("./components/pages/masters/MembershipBreakdown"));

const DashboardNew = lazy(() => import("./components/pages/DashboardNew"));
const DownloadCard = lazy(() => import("./components/musician/pages/DownloadCard"));

const SimpleDemoSidebar = lazy(() => import("./components/musician/pages/simpleDemoSidebar"));

const ChequePayment = lazy(() => import("./components/sections/payment/cheque-payment/Content"))

console.log('AFM Loves Web <3');

const tagManagerArgs = {
  gtmId: 'GTM-MLCG284',
}
TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview',
    userId: getUserInfo()?.userName,
  })

  useEffect(() => {
    ReactGa.initialize('G-DVCW73DE9Z')
    // var myElement = document.getElementById("root");
    // myElement.addEventListener("contextmenu", function(event) {
    //     event.preventDefault(); // Prevent the default right-click menu behavior
    // });
    //to report page view
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <Router>

      <Suspense fallback={<Spinner />}>
        <Switch>
          {/*Admin Routes*/}

          <Route path="/" component={Login} exact />
          {/* <Route path="/" component={Maintenance} exact /> */}
          {/* <Route exact path="/admin/login" component={Login} /> */}

          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login-with-token" component={loginWithToken} />
          <Route exact path="/signup-token" component={SignupWithToken} />
          <Route exact path="/signup-via-token" component={SignupWithTokenContent} />
          <Route exact path="/admin/logout" component={Logout} />
          <Route exact path="/forgot-password" component={Forgotpassword} />
          <Route exact path="/reset-password" component={Resetpassword} />
          <Route exact path="/change-temp-password/:Id" component={ChangeTempPassword} />
          <Route exact path="/two-factor-authentication" component={TwoFactorAuthentication} />
          <Route exact path="/forgot-username" component={ForgotUserName} />
          <Route exact path="/forgot-username/verify-email" component={VerifyEmailContent} />
          <Route exact path="/update-details" component={UserNameUpdateContent} />

          {/* Comman Routes */}
          <PrivateRoute path="/account/profile" component={Profile} exact />
          <Route path="/email-verification" component={EmailVerification} exact />

          {/* Private Routes */}
          <PrivateRoute path="/admin/musicians" component={Musicians} exact />
          <PrivateRoute path="/admin/guestpartialmusician" component={GuestPartialMusician} exact />
          {/* <PrivateRoute path="/admin/nonunionmember" component={NonUnionMember} exact />
          <PrivateRoute path="/admin/nonunionmember-forms" component={NonUnionMemberForms} exact /> */}
          <PrivateRoute exact path="/admin/nonunion-member" component={MusicianNonUnionMember} roles={[roles.admin]} />
          {/* <PrivateRoute path="/admin/contract-forms/edit/:contractID" component={NonUnionMemberForms} exact /> */}
          <PrivateRoute path="/musician/statements/:musicianID" component={MusicianStatemants} exact />
          <PrivateRoute path="/admin/musician/register-musician-using-link" component={MusicianRegistrationUsingLink} exact />
          <PrivateRoute path="/admin/musician/register-musician" component={Musicianregistration} exact />
          <PrivateRoute path="/musician/edit/:musicianIDParameter" component={Musicianregistration} exact />
          <PrivateRoute path="/directory-search" component={DirectorySearch} exact />
          <PrivateRoute path="/interim-statement/:musicianID" component={InterimStatement} exact />
          <PrivateRoute path="/documents/:musicianID" component={Documents} exact />

          {/* MusicianOnlyProfileSection */}
          <PrivateRoute path="/musician-profile" component={MusicianOnlyProfile} exact />
          <PrivateRoute path="/musician-statements" component={MusicianPrfStatemants} exact />
          <PrivateRoute path="/musician-transactions" component={MusicianPrfTransactions} exact />\
          <PrivateRoute path="/interim-statement" component={MusicianPrfInterimStatement} exact />
          <PrivateRoute path="/documents" component={MusicianPrfDocuments} exact />
          <PrivateRoute path="/musician-jobs" component={MusicianPrfJobs} exact />
          <PrivateRoute path="/job-details" component={JobsDetails} exact />
          <PrivateRoute path="/workdue-payment" component={MusicianPrfWorkduePayment} exact />
          <PrivateRoute path="/membership-payment" component={MusicianPrfMembershipPayment} exact />
          <PrivateRoute path="/donation-payment" component={MusicianPrfDonationPayment} exact />
          <PrivateRoute path="/payment-response" component={MusicianPaymentResponse} exact />

          {/* WeeklyReport */}
          <PrivateRoute path="/weekly-report" component={WeeklyReport} exact />

          {/* Jobs */}
          <PrivateRoute path="/admin/b-forms" component={Bforms} exact />
          <PrivateRoute path="/create-jobs" component={CreateJobs} exact />
          <PrivateRoute path="/edit-jobs/:jobIDParameter" component={CreateJobs} exact />
          <PrivateRoute path="/admin/b-forms/edit/:jobIDParameter" component={Bforms} exact />
          <PrivateRoute path="/jobs/inactive" component={Jobs} exact />
          <PrivateRoute path="/jobs/musician" component={MJobs} exact />
          <PrivateRoute path="/jobs" component={Jobs} roles={[roles.admin]} exact />
          <PrivateRoute path="/musician/transactions/:musicianID" component={MusicianTransactions} exact />
          <PrivateRoute path="/musician/:jobId/:sessionNo" component={MJobs} exact />
          <PrivateRoute path="/musician/musician-jobs/:musicianID" component={MusicianJobs} exact />
          <PrivateRoute path="/musician-jobs/:musicianID" component={MusicianJobs} exact />

          <PrivateRoute path="/membership-payment/:musicianID" component={MembershipPayment} exact />
          <PrivateRoute path="/donation-payment/:musicianID" component={DonationPayment} exact />
          <PrivateRoute path="/payment/response/:typeOfPayment/" component={PaymentResponse} exact />
          <PrivateRoute path="/admin/workdue-payment/:musicianID" component={WorkduePayment} exact />

          <PrivateRoute path="/jobs/:formName/:jobID" component={PDF} exact />

          {/* transactions */}
          <PrivateRoute path="/transctions" component={Transactions} roles={[roles.admin, roles.musician]} exact />

          {/* creditcard print page */}
          <PrivateRoute path="/credit-card-transctions/:TransHeaderId" component={PrintCreditCardTransaction} exact />

          {/* reports */}
          <PrivateRoute path="/reports" component={Reports} roles={[roles.admin]} exact />

          {/* Hearing Board */}
          <PrivateRoute path="/admin/hearing-board" component={HbReminder} roles={[roles.admin]} exact />
          <PrivateRoute path="/hb-statements/:hbID" component={HearingBoardStatement} exact />

          <PrivateRoute path="/admin/hearingboard" component={HearingBoard} roles={[roles.admin]} exact />

          {/* TaftHarley */}
          <PrivateRoute path="/admin/taft-hartley" component={TaftHartley} roles={[roles.admin]} exact />
          <PrivateRoute path="/admin/taft-hartley/musician-letter" component={MusicianLetter} roles={[roles.admin]} exact />
          <PrivateRoute path="/admin/taft-hartley/employer-letter" component={EmployerLetter} roles={[roles.admin]} exact />
          <PrivateRoute path="/admin/taft-hartley/leader-letter" component={LeaderLetter} roles={[roles.admin]} exact />
          <PrivateRoute path="/admin/past-jobs/:MusicianId" component={MusicianPastJobs} roles={[roles.admin]} exact />

          {/* fed report */}
          <PrivateRoute path="/admin/fed-report" component={FedRerport} roles={[roles.admin]} exact />

          {/* custom api */}
          <PrivateRoute path="/admin/custom-api" component={CustomApi} roles={[roles.admin]} exact />

          {/* monthlyInvoice */}
          <PrivateRoute path="/admin/monthly-invoice" component={MonthlyInvoice} roles={[roles.admin]} exact />

          {/* Contracts */}
          {/* <PrivateRoute path="/admin/contracts" component={Contract} exact /> Show Contract Table*/}
          <PrivateRoute path="/admin/contract" component={Contract} exact />
          <PrivateRoute path="/admin/contract-forms" component={ContractForms} exact />
          <PrivateRoute path="/admin/contract-forms/edit/:contractID" component={ContractForms} exact />

          {/* RehearsalRoom */}
          <PrivateRoute path="/rehearsal-room" component={RehearsalRoom} exact />

          {/* Masters */}
          {/* <PrivateRoute exact path="/admin/masters/users" component={User} roles={getUserInfo()?.CurrentRolesId} /> */}
          <PrivateRoute exact path="/admin/masters/users" component={User} roles={[roles.admin, roles.developer]} />
          <PrivateRoute exact path="/admin/masters/user-roles" component={Userrole} roles={[roles.admin, roles.developer]} />
          <PrivateRoute exact path="/admin/masters/phone-types" component={Phonetype} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/relations" component={Relation} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/instruments" component={Instrument} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/country" component={Country} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/state" component={State} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/city" component={City} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/instruments-roles" component={Instrumentroles} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/terms-and-condition" component={Termsandcondition} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/musician-status" component={Musicianstatus} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/web-permissions" component={Webpermissions} roles={[roles.admin, roles.developer]} />
          <PrivateRoute exact path="/admin/masters/bank-details" component={Bankdetails} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/employer" component={Employers} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/employerdetails/add" component={EmployerDetails} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/employerdetails/edit/:EmpId" component={EmployerDetails} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/settings" component={Settings} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/membershipduration" component={MembershipDuration} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/membershipbreakdown" component={MembershipBreakdown} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/latefees" component={LateFees} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/paymenttype" component={PaymentType} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/engagementcategory" component={EngagementCategory} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/scaleheader" component={ScaleHeader} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/scaledetails/add" component={ScaleHeaderDetails} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/scaledetails/:scaleGUID" component={ScaleHeaderDetails} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/signatory" component={Signatory} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/document-category" component={DocumentCategory} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/document-upload" component={DocumentUpload} roles={[roles.admin]} />
          <PrivateRoute exact path="/admin/masters/status-wise-permission" component={StatusWisePermission} roles={[roles.admin, roles.developer]} />
          <PrivateRoute exact path="/admin/masters/membership-permission" component={MembershipPermission} roles={[roles.admin, roles.developer]} />
          <PrivateRoute exact path="/admin/masters/member-status-permissions" component={MembStatusPermissions} roles={[roles.admin, roles.developer]} />
          <PrivateRoute path="/dashboard-new" component={DashboardNew} roles={[roles.musician]} exact />
          <PrivateRoute path="/admin/masters/email-template" component={EmailTemplate} roles={[roles.admin, roles.musician, roles.developer]} />
          <PrivateRoute path="/admin/masters/overture" component={Overture} roles={[roles.admin]} />
          <PrivateRoute path="/admin/masters/reports" component={ReportMaster} />
          <PrivateRoute path="/posting-date-update" component={PostingDateUpdate} />
          <PrivateRoute path="/print-preview" component={PrintPreview} />
          <PrivateRoute exact path="/admin/masters/engagement-sub-category" component={EngagementSubCategory} roles={[roles.admin]} />
          <PrivateRoute path="/admin/masters/print-template" component={PrintTemplate} roles={[roles.admin, roles.musician, roles.developer]} />
          <PrivateRoute path="/employer-check" component={ChequePayment} exact />
          <PrivateRoute path="/download-card" component={DownloadCard} />
          {/* Musician Profile */}
          <PrivateRoute exact path="/musician-profile" component={MusicianProfile} />
          <Route exact path="/demo-sidebar" component={SimpleDemoSidebar} />

          <Route exact path={"/unauthorized"} component={UnAuthorized} />
          <Route exact path="*" component={Notfound} />

        </Switch>
      </Suspense>
      {/* <Footerauthor /> */}
    </Router>

  );
}

export default App;