import * as actionTypes from '../../action-types/jobs/jobs-action-types';

const initialState = {
    category: null,
    tabForm: null,
    engagementCategoryFormInfo: null,
    jobID: null,
    AutoID: null,
    filmSessions: [],
    musicians: [],
    hours: null,
    jobNo: null,
}

export const jobReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CATEGORY:
            return { ...state, category: action.payload }
        case actionTypes.SET_TAB_FORM:
            return { ...state, tabForm: action.payload }
        case actionTypes.SET_ENGAGEMENT_CATEGORY_FORM_INFO:
            return { ...state, engagementCategoryFormInfo: action.payload }
        case actionTypes.SET_JOB_ID:
            return { ...state, jobID: action.payload }
        case actionTypes.SET_FILM_SESSION:
            return { ...state, filmSessions: action.allSessions }
        case actionTypes.SET_SUB_FORM_DATA:
            return { ...state, subForm: action.allSessions }
        case actionTypes.SET_AUTO_ID:
            return { ...state, AutoID: action.payload }
        case actionTypes.SET_MUSICIANS:
            return { ...state, musicians: action.payload }
        case actionTypes.SET_HOURS:
            return { ...state, hours: action.payload }
        case actionTypes.SET_JOB_NO:
            return { ...state, jobNo: action.payload }
        case actionTypes.SET_REINITIALISATION:
            return {
                ...state,
                category: null,
                tabForm: null,
                engagementCategoryFormInfo: null,
                jobID: null,
                AutoID: null,
                filmSessions: [],
                musicians: [],
                hours: null,
                jobNo: null
            }
        default:
            return state
    }
}