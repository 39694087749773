import * as actionTypes from '../../action-types/b-form-action-types/b-form-action-types'

const initialState = {
    basicInfo: [],
    tunes: [],
    additionalInformation: [],
    musicians: [],
    documents: []
}
export const bFormJobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_BASIC_INFO:
            return { ...state, basicInfo: action.payload }
        case actionTypes.ADD_TUNES:
            return { ...state, tunes: action.payload }
        case actionTypes.ADD_ADDITIONAL_INFORMATION:
            return { ...state, additionalInformation: action.payload }
        case actionTypes.ADD_MUSICIANS:
            return { ...state, musicians: action.payload }
        case actionTypes.ADD_ADDITIONAL_DOCS:
            return { ...state, documents: action.payload }
        default:
            return state
    }
}