import * as actionTypes from '../action-types/musician-job-types'
const initialState = {
    jobs: [],
}
const musicianReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MUSICIAN_JOBS:
            return { ...state, jobs: action.payload.allJobs }
        default:
            return state
    }
}
export default musicianReducer
