export const roles = {
    musician: '2ABDC9E2-1C03-4788-8DD2-26174D050E34',
    admin: '6BDFA417-0658-451A-BFB2-B5002828A1D9',
    superAdmin: '734B1024-8DCD-4BD7-9C40-87C0068B4809',
    developer: '8BB60DCB-B3E3-4EF0-8602-F27579FF855A',
    partialMusician: 'F3B7E86A-5B25-4D57-A94B-62E664AB27C1',
    guestMusician: 'E77A97FB-7B9D-484B-87E7-BEEED8C5A56D', //this is staff in acceptable
    contractor: "720B4028-AD8E-4904-83EC-284BD7DEBB56",
    nonunionmember: "7EB4F3C6-0B68-43DD-A996-17D141A1C3BA",
    dataAnalyst: "0FE27597-DB58-4E1D-B64D-8D96D49353B6",
    financeOfficer: "4EF9E509-C05A-4BF3-A486-5CAE98C2BD2E",
    businessRep: "6E7F1504-3385-4FE2-8D02-BF8CC3EC8987",
    Officer: "B653CA61-D47B-478F-901E-30DE32AD0DDC",
    fundsUsers: "BDA198E8-A53E-4EB2-B733-2555E108377C",
    membership: "23578697-9166-4A7D-980B-51EAF18F3F82"
}
// DO NOT EDIT THESE VALUES : GUID