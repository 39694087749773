import * as actionTypes from '../action-types/membership-and-workdue-payment-action-types';

const initialState = {
    duration: null,
    paymentType: null,
    otherPayment: null,
    IsActiveUser: 1,
    TotalPayableAmt: 0,
    shouldUseBalanceToPay: 1,
    TransTermCondition: 1,
    TransTermConditionError: null,
    DurationIdError: null,
    IsWorkDuesToPay: 1,
    jobsToPayWD: [],
    Year: null,
    TotalCreditBalance: null,
    totalAmount: 0,
    membershipDuesFee: 0,
    _strPostingMusicianStatus: null,
    _strCheckReceivedDate: null

}
const membershipPaymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SET_PAYMENT_TYPE:
            return { ...state, paymentType: action.payload }
        case actionTypes.TOTAL_PAYMENT_AMOUNT:
            return { ...state, TotalPayableAmt: action.payload }
        case actionTypes.SET_PAYMENT_DURATION:
            return { ...state, duration: action.payload }
        case actionTypes.SHOULD_USE_BALANCE_TO_PAY:
            return { ...state, shouldUseBalanceToPay: action.payload }
        case actionTypes.SET_IS_ACTIVE_USER:
            return { ...state, IsActiveUser: action.payload }
        case actionTypes.SET_IS_TERMS_AND_CONDITION:
            return { ...state, TransTermCondition: action.payload }
        case actionTypes.SET_TERMS_AND_CONDITION_ERROR:
            return { ...state, TransTermConditionError: action.payload }
        case actionTypes.SET_DURATION_ERROR:
            return { ...state, DurationIdError: action.payload }
        case actionTypes.SET_JOBS_TO_PAY_WD:
            return { ...state, jobsToPayWD: action.payload }
        case actionTypes.OTHER_PAYMENTS:
            return { ...state, otherPayment: action.payload }
        case actionTypes.Is_WorkDues_To_Pay:
            return { ...state, IsWorkDuesToPay: action.payload }
        case actionTypes.SET_PAYMENT_YEAR:
            return { ...state, Year: action.payload }
        case actionTypes.TOTAL_CREDIT_BALACE:
            return { ...state, TotalCreditBalance: action.payload }
        case actionTypes.TOTAL_AMOUNT:
            return { ...state, totalAmount: action.payload }
        case actionTypes.MEMBERSHIP_DUES_AMOUNT:
            return { ...state, membershipDuesFee: action.payload }
        case actionTypes.MEMBER_NEW_STATUS:
            return { ...state, _strPostingMusicianStatus: action.payload }
        case actionTypes.CHECK_RECEIVED_DATE:
            return { ...state, _strCheckReceivedDate: action.payload }
        default:
            return state
    }
}
export default membershipPaymentReducer;
