import axios from "axios"
import { Host, Endpoints, getUserInfo } from "../../helpers/comman_helpers"

export const ReportsUrl = (type) => {
    var url = '';
    url = Host +'report/'+type;
    return url;
}
export const ExportsUrl = (type) => {
    var url = '';
    url = Host +'Export/'+type;
    return url;
}

export const getReportsApi = (data = null) => {
    let url = Host + Endpoints.getReports + "/get";
    
    let headers = {
        headers:{
            Authorization: `Bearer ${getUserInfo().access_token}`
        }
    }

    return axios.post(url, data, headers);
}

export const getUsersTransactionsCreatedbyApi = (data = null, type) => {
    let url = Host + Endpoints.getTransactionsCreatedby

    const headers = {
        headers: {
            Authorization: `Bearer ${getUserInfo()?.access_token}`
        }
    }
    return type === 'get' ? (url = url + "/get", axios.post(url, data, headers)) :
        type === 'post' ? (axios.post(url, data, headers)) :
            type === 'put' ? (axios.put(url, data, headers)) : ''
}