export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export const TOTAL_PAYMENT_AMOUNT = 'TOTAL_PAYMENT_AMOUNT'
export const SET_PAYMENT_DURATION = 'SET_PAYMENT_DURATION'
export const SHOULD_USE_BALANCE_TO_PAY = 'SHOULD_USE_BALANCE_TO_PAY'
export const SET_IS_ACTIVE_USER = 'SET_IS_ACTIVE_USER'
export const SET_IS_TERMS_AND_CONDITION = 'SET_IS_TERMS_AND_CONDITION'
export const SET_TERMS_AND_CONDITION_ERROR = 'SET_TERMS_AND_CONDITION_ERROR'
export const SET_DURATION_ERROR = 'SET_DURATION_ERROR'
export const SET_JOBS_TO_PAY_WD = 'SET_JOBS_TO_PAY_WD'
export const OTHER_PAYMENTS = 'OTHER_PAYMENTS'
export const Is_WorkDues_To_Pay = 'Is_WorkDues_To_Pay'
export const SET_PAYMENT_YEAR = 'SET_PAYMENT_YEAR'
export const TOTAL_CREDIT_BALACE = 'TOTAL_CREDIT_BALACE'
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT'
export const MEMBERSHIP_DUES_AMOUNT = 'MEMBERSHIP_DUES_AMOUNT'
export const MEMBER_NEW_STATUS = 'MEMBER_NEW_STATUS'
export const CHECK_RECEIVED_DATE = 'CHECK_RECEIVED_DATE'

