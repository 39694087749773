import * as actionTypes from '../action-types/current-page-action';

const initialState = {
    currentPage: null
}

export const currentPageReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload }
        default:
            return state;
    }
}