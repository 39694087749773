import * as actionTypes from '../action-types/musicians-additional-docs-types'
const initialState = {
    documents: []
}
export const musiciansAdditionalDocsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MUSICIAN_ADDITIONAL_DOCS:
            return { ...state, documents: action.payload }
        default:
            return state
    }
}