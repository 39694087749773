export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_TAB_FORM = 'SET_TAB_FORM';
export const SET_ENGAGEMENT_CATEGORY_FORM_INFO = 'SET_ENGAGEMENT_CATEGORY_FORM_INFO';
export const SET_JOB_ID = 'SET_JOB_ID'
export const SET_FILM_SESSION = 'SET_FILM_SESSION'
export const SET_SUB_FORM_DATA = 'SET_SUB_FORM_DATA';
export const SET_AUTO_ID = 'SET_AUTO_ID';
export const SET_MUSICIANS = 'SET_MUSICIANS';
export const SET_REINITIALISATION = 'SET_REINITIALISATION';
export const SET_HOURS = 'SET_HOURS';
export const SET_JOB_NO = 'SET_JOB_NO';
 