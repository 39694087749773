import { combineReducers } from "redux";
import musicianJobsReducer from './reducers/musician-jobs-reducers';
import musicianTransactionsReducer from './reducers/musician-transaction-reducers';
import filterReducer from "./reducers/filter-reducers";
import { bFormJobsReducer } from "./reducers/b-form-reducers/b-form-reducers";
import { musiciansAdditionalDocsReducer } from './reducers/musicians-additional-docs-reducers';
import memberShipReducer from './reducers/membership-payment-reducers';
import modalReducer from "./reducers/modal-reducers/modal-reducers";
// Master Reducers
import { currentPageReducer } from "./reducers/current-page-reducers";

import { masterReducer } from "./reducers/master-reducers/master-reducers";
import { jobReducers } from "./reducers/jobs/job-reducers";
import musicianStatusReducer from "./reducers/musician-status-reducers";

// import thunk from 'redux-thunk';
// import { persistStore, persistReducer } from 'redux-persist';
const rootReducer = combineReducers({
    jobs: jobReducers,
    musicianJobs: musicianJobsReducer,
    musicianTransactions: musicianTransactionsReducer,
    payment: memberShipReducer,
    modals: modalReducer,
    filters: filterReducer,
    masters: masterReducer,
    currentPage: currentPageReducer,
    bFormJobs: bFormJobsReducer,
    musiciansAdditionalDocs: musiciansAdditionalDocsReducer,
    musicianStatus:musicianStatusReducer
})

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// const configureStore = () => {
//     return createStore(persistedReducer, compose(applyMiddleware(thunk)));
// }

// export default configureStore;





export default rootReducer;

