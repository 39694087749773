import React from 'react'

import  '../../assets/css/portal.css';
import  '../../assets/css/bootstrap.min.css';
import  '../../assets/css/app.min.css';

const Spinner = (props) => {
    const { size, center } = props;

    return (
        <>
            {size ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                <div className="screen-loader d-flex justify-content-center">
                    <div className="spinner-border" role="status" aria-hidden="true">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </>
    )
}

export default Spinner