import React from 'react';
import { Route } from 'react-router-dom';
import { getUserInfo } from '../../../helpers/comman_helpers';
import { roles } from '../../../data/roles-permission/roles';
import { Redirect } from 'react-router-dom';

// REST vs Spread
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        console.log('reast...', rest)
        const currentUser = getUserInfo();
        // console.log('rest....', rest)
        const currentUrlPath = rest.path
        let currentUrl = "";
        let isFlagPageAceess = false;

        if (currentUrlPath.includes(":")) {
            const colonCount = (currentUrlPath.match(/:/g) || []).length;
            if (colonCount == 1) {
                const urlSplit = currentUrlPath.split(":");
                currentUrl = urlSplit[0];
                isFlagPageAceess = true;
                console.log('colon here1')
            } else {
                const lastColonIndex = currentUrlPath.lastIndexOf(":");
                if (lastColonIndex !== -1) {
                    const strbeforelastColon = currentUrlPath.substring(0, lastColonIndex);
                    const strafterlastColon = currentUrlPath.substring(lastColonIndex)
                    currentUrl = strbeforelastColon + strafterlastColon;
                    isFlagPageAceess = true;
                    // console.log('colon here2')
                }
            }
        } else {
            currentUrl = currentUrlPath
            // console.log('currentUrl....WITHOUTColon', currentUrl)
        }
        // console.log('currentUrl....', currentUrl)
        // console.log('isFlagPageAceess....', isFlagPageAceess)
        // If Not logged in so redirect to login page with the return url
        if (!currentUser) {
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        // if (rest.roles && rest.roles.includes(getUserInfo().CurrentRolesId) === false) {
        //     // role not authorised so redirect to Unauthorized Page
        //     return <Redirect to={{ pathname: '/unauthorized' }} />
        // }

        // Check if the user has the required permission for the current URL
        let userPermissions = JSON.parse(currentUser.permission);

        if (Array.isArray(userPermissions)) {
            const isAuthorized = userPermissions.some(permission => {
                if (permission.WebPermissionPageName == currentUrl && permission.WebPermissionControlType === "Page") {
                    console.log('contion1', currentUrl)
                    return true;
                } else if (permission.WebPermissionPageName == currentUrl && isFlagPageAceess === true) {
                    console.log('contion2', currentUrl)
                    return true
                } else if (permission.WebPermissionPageName == currentUrl && permission.WebPermissionControlType !== "" && permission.WebPermissionControlName !== "view" && isFlagPageAceess === true) {
                    console.log('contion3,,permission.WebPermissionControlType', currentUrl, permission.WebPermissionControlType)
                    return true
                }
                else if (permission.WebPermissionPageName == currentUrl && permission.WebPermissionControlType == "Component") {
                    console.log('contion4,,permission.WebPermissionControlType', currentUrl, permission.WebPermissionControlType)
                    return true
                }
                // console.log('permission.WebPermissionPageName....', permission.WebPermissionPageName)
                // return permission.WebPermissionPageName === currentUrl && ((isFlagPageAceess === true || permission.WebPermissionControlType === "Page") || (permission.WebPermissionControlName !== "" && permission.WebPermissionControlName !== "view"));
            });
            console.log('permission.isAuthorized....', isAuthorized)
            if (currentUrl !== "/dashboard" && !isAuthorized) {
                // If the user is not authorized, redirect to the unauthorized page
                return <Redirect to={{ pathname: '/unauthorized' }} />;
            }
        } else {
            return <Redirect to={{ pathname: '/unauthorized' }} />;
        }

        // Check if the token has expired (greater than 24 hours)
        const issuedDate = new Date(currentUser[".issued"]);
        const expiresDate = new Date(currentUser[".expires"]);
        const currentDate = new Date();
        const timeDifIssExpDate = expiresDate - issuedDate;
        const timeDifference = currentDate - issuedDate;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        const hoursDifIssExpDate = timeDifIssExpDate / (1000 * 60 * 60);

        if (hoursDifference >= 24 && hoursDifIssExpDate >= 24) {
            // If token has expired, redirect to the login page
            window.localStorage.clear();
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
export default PrivateRoute;