import toast from 'react-hot-toast';
import { ReportsUrl } from '../data/api/Reports';
import bowser from 'bowser';
export const version = 5

export const EMAIL_REGEX = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const PASS_REGEX = (str) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^()])[A-Za-z\d@.#$!%*?&^()]{12,30}$/;
    // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{12,30}$/;
    return re.test(String(str));
}

export const CheckPasswordStrength = (password, isFocused) => {
    let passwordStrength = "";
    let color = "";

    // If textBox is empty or not focused
    if (password.length === 0 || !isFocused) {
        passwordStrength = "";
        color = "";
    } else {
        // Regular Expressions
        const regex = [
            "[A-Z]",     // For Uppercase Alphabet
            "[a-z]",     // For Lowercase Alphabet
            "[0-9]",     // For Numeric Digits
            // "[$@$!%*#?&]" // For Special Characters
            "[$@!%*#?&.^()]"
        ];

        let passed = 0;

        // Validation for each Regular Expression
        for (let i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(password)) {
                passed++;  //is used to keep track of how many of the password criteria have been met.
            }
        }

        // Display of Status
        if (password.length < 12) {
            passwordStrength = "Password is Weak.";
            color = "red";
        } else if (passed === regex.length && password.length === 12) {
            passwordStrength = "Password is Good.";
            color = "darkorange";
        } else if (passed === regex.length && password.length > 12) {
            passwordStrength = "Password is Strong.";
            color = "green";
        }
    }
    return { passwordStrength, color };
};

// export const Host = 'http://localhost:45678/';
// For Production
export const Host = window.location.host === 'ensemble47.azurewebsites.net' ? 'https://ensemble47api.azurewebsites.net/' : 'https://ensemble47api.azurewebsites.net/'
// For Development
// export const Host = window.location.host === 'devafmlocal47site.azurewebsites.net/' ? 'https://devafmlocal47api.azurewebsites.net/' : 'https://devafmlocal47api.azurewebsites.net/'
// export const Host = window.location.host === 'webensemble2-acceptable.azurewebsites.net' ? 'https://ensemble2.azurewebsites.net/Acceptable/' : 'https://ensemble2.azurewebsites.net/'

export const Endpoints = {
    //MusiciansOnlyProfileSection
    musiciansProfileDashboard: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/dashboard",
    musiciansProfileInfo: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/info",
    musiciansPersonalDataGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mbrgt",
    musiciansPersonalDataUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msetprl",
    musiciansProfileAddiAddrGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/adadsgt",
    musiciansProfileAddiAddrUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msadadrs",
    musiciansProfileEmergencyContactGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mbremctgt",
    musiciansProfileEmergencyContactUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msemcyctct",
    musiciansProfileInstrumentGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/instgt",
    musiciansProfileInstrumentUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msistrntupt",
    musicianProfileBeneficiaryGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mbbfygt",
    musicianProfileBeneficiaryUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msbfcy",
    musicianProfileConfirmDetailsGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/cfrmDtlgt",
    musicianProfileConfirmDetUpdate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mscfmDls",
    musiciansProfileStatements: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mstmtgt",
    musiProfileMembershipStatements: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mbrstmtgt",
    musiProfileTransactionsHeader: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/trngt",
    musiProfileTransactionsDet: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/trsdtgt",
    musiPrfTransactionHeaderCardDet: "api/transaction-header-CardDetail",
    musiProfileInterinStatement: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/itrmstmtgt",
    musiciansProfileJobs: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msjfatch",
    musicianProfileNewJob: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/nejbgt",
    musiciansProfileDirDrch: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/dirsrch",
    musicianPrfUsersGt: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/ugrgt",
    musiProfileUploadDocumentHierarchy: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mbrdcgt",
    musiPrfContactPreferences: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/msprfrc",
    musiPrfMembershipPayment: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/mpt",
    musiPrfWorkDuePayment: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/wkdept",
    musicianPrfAllPaymentsType: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/gtalmbrpyttp",
    getMusiPrfStartendDate: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/gtstreddt",
    getMusiPrfOverture: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/ovtrgt",
    musiPrfPhoneType: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/ptypgt",
    musiPrfRelation: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/rltnsgt",
    musicianProfilePic: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/prlpc",
    musicianPrfchangePassword: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/cgpswrd",
    musiPrfDownloadCard: "api/b98af8f0-2a84-45c8-96ef-b27e8e8b30bd/gnmrcrd",

    userType: "api/usertype",
    phoneType: "api/phonetype",
    relation: "api/relations",
    users: "api/users",
    signIn: "token",
    country: "api/country",
    state: "api/state",
    city: "api/city",
    bank: "api/bankdetails",
    instrumentRole: "api/instrumentroles",
    termsAndCondition: "api/termscondition",
    musicianStatus: "api/musicianstatus",
    webPermissions: "api/webadminpermissions",
    bankdetails: "api/bankdetails",
    roles: "api/userrole",
    musician: "api/musicians",
    userExist: "api/userExist",
    MusicianSearchByName: "api/MusicianSearchByName",
    interinStatement: "api/interim-statement",
    changeBadInfoFlag: "api/musicians/badstatus",
    changeBadAccountFlag: "api/musicians/badaccount",
    directorySearch: "api/musicians/directorysearch",
    instrument: "api/instruments",
    userRolesPermission: "api/userrolepermission",
    employer: "api/employer-afm", // to get the employe of afm
    employerAddress: "api/employer-address",
    employerContact: "api/employer-contacts",
    employerContract: "api/employer-contracts",
    employerAFMContract: "api/afm-contracts",
    employerDocs: "api/Employer-additional-doc",
    jobEmployer: "api/jobemployer",
    jobAFMEmployer: "api/jobafm-employer",
    employersignatory: "api/signatory", // 
    employersignatoryList: "api/signatoryList", // 
    performanceAgreementList: "api/agreement-type/get-employer",
    engagementCategory: "api/engagement-category",
    engagementSubCategory: "api/contract-agreement-type",
    agreementType: "api/agreement-type",
    scaleType: "api/scale-header",
    scaleDetails: "api/scale",
    settings: "api/settings",
    membershipduration: "api/membershipduration",
    membershipBreakdown: "api/MembershipBreakDown",
    musicianstatus: "api/musicianstatus",
    latefees: "api/latefees",
    paymenttype: "api/payment-type",
    documentCategory: "api/documentcategory",
    getDocumentCategoryUserRoles: "api/getdocumentcategoryuserroles",
    getDocumentCategoryUser_Status: "api/getdocumentcategoryuser_Status",
    documentcategoryHierarchy: "api/documentcategoryHierarchy",
    documentUpload: "api/upload-document",
    uploadDocumentHierarchy: "api/upload-documenthierarchy",
    statusWisePermission: "api/status-wise-permission",
    membershipPermission: "api/membership-permission",
    emailTemplate: "api/emailtemplate",
    emailVariable: "api/emailvariable",
    emailTemplateType: "api/emailTemplateType",
    printTemplate: "api/Printtemplate",
    overture: "api/Overture",
    getOverture: "api/Overture",
    uploadImage64: "api/upload-document/get-base64",
    MemberStatusPermission: "api/MemberStatusPermission",
    contactPreferences: "api/musicians-preference",
    reportMaster: "api/report-master",
    getReportsUserRoles: "api/reportuserroles",
    // Musician Registration
    emergencyContact: "api/musiciansemergencycontact",
    additionalAddress: "api/musicians-addi-address",
    musicianBeneficiary: "api/musiciansbeneficiary",
    musiciansInstrument: "api/musiciansinstrumentdata",
    musiciansConfirmDetails: "api/musiciansconfirmDetails",
    confirmSaveCardDetails: "api/musicianAfterConfirm/saveCardDetail",
    registerMusicianViaLink: "api/musician/register-via-link",
    musiciansMemo: "api/musicians-memo",
    musiciansDates: "api/musicians-dates",
    musiciansDocs: "api/musicians-additional-doc",
    musicianBalance: "api/musician-balance",
    musiciansStatements: "api/monthly-statement",
    stopLateFee: "api/musician-stopLateFee",
    membershipStatements: "api/membership-statement",
    getStatmentJobs: "api/WorkDue-Refund-WaveOff/getstatmentjobs",
    musiciansWaiveOff: "api/WorkDue-Refund-WaveOff",
    nonunionMember: "api/musicians-nonunionmember",
    yearWiseAllTransactions: "api/musician-balance/getyearwise-alltransaction",
    //Dashboard
    adminDashboard: "api/admin/dashboard",
    musicianDashboard: "api/musicians/dashboard",
    musicianInfo: "api/musicians-info",


    forgotPassword: "api/users/forgotpassword",
    resetPassword: "api/users/forgot-change-password",
    signUp: "api/users/signup",
    tokenWiseLogin: "api/users/token-wise-login",
    SignupViaToken: "api/users/signup-via-token",
    sendOtp: "api/sendOTP",
    verifyOtp: "api/sendOTP/auth",
    usersContactUpdate: "api/users-contact-update",

    changePassword: "api/users/change-password",
    changeTempPassword: "api/users/change-temp-password",
    profilePic: "api/users/insertupdateprofilepic",
    unlockUser: "api/admin/UnlockUser",


    // Jobs
    job: "api/jobs",
    jobTunes: "api/map-jobs-tunes",
    jobMusicians: "api/map-jobs-musician",
    getPercentageByRole: "api/map-jobs-musician/get-percentage-by-roles",
    jobDocs: "api/jobs/jobs-docs",
    getJobDocs: "api/jobs/get-jobs-docs",
    newJob: "api/new-job",
    newJobUpdate: "api/new-job/update",
    musicJob: "api/musicians-for-jobworkdue",
    newJobsDetails: "api/new-jobs-details",
    autoPopulateNumber: "api/getlatest-filmsession-performanceno",
    changeJobStatus: "api/new-job-status",
    reverseMusicianJobs: "api/map-jobs-musician/reverse",

    //Contract
    contract: "api/contract",
    contractCategory: "api/contract-category",
    contractAgreementType: "api/contract-agreement-type",
    contractScale: "api/contract-scale",
    contractType: "api/contract-type",
    //get employer
    employer: "api/employer-afm",

    // Musician Jobs
    MusicianJobs: "api/musician-job",
    employerJobs: "api/employer-jobs",
    employerPayments: "api/employer-multicheck-payments",
    employerWDPayments: "api/EmployerMulticheckWdPayments",
    employerHWPayments: "api/EmployerMulticheckHWPayments",
    employerPensionPayments: "api/EmployerMulticheckPSPayments",
    transactions: "api/transactions",
    transactionsHeader: "api/transaction-header",
    transactionHeaderCardDetail: "api/transaction-header-CardDetail",
    reverseTransactionsPayment: "api/transactions/reverse",
    transactionsGetReversed: "api/transactions/get_reversed",
    postingDateUpdate: "api/change-posting-date",

    additionalDocs: "api/job-additional-doc",
    verifyEmail: "api/users/email-changed",
    membershipPayment: "api/membership-payment",
    membershipDuration: "api/membershipduration",
    getStartendDate: "api/membershipduration/get-startend-date",
    musicianAllPayments: "api/get-allmusicianpayment-type",
    workDuePayment: "api/workdue-payment",

    //  New JOB In used
    newJob: "api/new-job",
    newJobList: "api/new-job-list",
    newJobDetails: "api/new-jobs-details",
    jobSessionDetails: "api/session-details",
    filmSessions: "api/new-jobs-details",
    JobBenefits: "api/map-job-benefit",
    employerContracts: "api/employer-contracts",
    musicianBySSN: "api/map-jobs-musician/get-musician-by-ssn",
    paymentDropdown: "api/job-paymenttype",
    cycleDates: "api/CycleDate",

    //reports urls
    getReports: "api/report-master",
    getTransactionsCreatedby: "api/users/get-transactions-createdby",

    //taft hartley
    taftHartley: "api/musicians-dates",
    taftHartleyEmployerLetter: "api/taft-hartley-employer",
    taftHartleyMusicianLetter: "api/THdata",
    thEmployerLetter: "api/THEmpdata",
    thLeaderdata: "api/THLeaderdata",
    THLetterPrintDate: "api/THLetterPrintDate/post",
    markedPrintReport: "api/THLetterSetPrintAndEmailDate/post",

    //hearing board
    hearingBoard: "api/hearing-board",
    hearingBoardStatus: "api/hearing-board",
    hbStatementDetails: "api/hearing-board-details",
    hbReminder: "api/HBdata",
    hbReminderCommInsert: "api/HBdataComment/Insert",
    hbComment: "api/hearing-board-comment",
    monthlyStmtSchedule: "api/MSdata",
    //fed reports
    fedReport: "api/fedreport",
    //custom api
    importSignatory: "report/ImportSigFile",
    importSignatoryDetails: "report/ImportSigDtlFile",
    monthlyInvoice: "api/Grouped_MS"
}
export const notify = (message, type) => {
    type === 'error' ? toast.error(message) : toast.success(message)
}
export const catchError = "An error occurred-please contact your system administrator!";
export const rowLimit = 10;
export const ssnMaxLength = 11;
export const phoneNumberMaxLength = 10
export const adminTokenName = 'afm-admin-token';
export const jobsTokenName = 'afm-jobs-token';
export const musiciansTokenName = 'afm-musicians-token';
export const cardNumberLength = 16;
export const cvvLength = 3;
export const expiryLength = 4;
export const currentDate = new Date().toISOString().split('T')[0];

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(adminTokenName));
}
export const currentUserRole = getUserInfo()?.CurrentRolesId;

export const getjobsHistory = () => {
    return JSON.parse(localStorage.getItem(jobsTokenName));
}
export const getmusiciansHistory = () => {
    return JSON.parse(localStorage.getItem(musiciansTokenName));
}
export const MusiciansCurrentRoles = () => {
    const musiciansCurrentRolesId = getUserInfo()?.CurrentRolesId;
    const musiciansOnlyRoles = ['2ABDC9E2-1C03-4788-8DD2-26174D050E34', '7EB4F3C6-0B68-43DD-A996-17D141A1C3BA'];

    const hasMatchingRole = musiciansOnlyRoles.includes(musiciansCurrentRolesId);
    return hasMatchingRole;
};

export const getAdminRole = () => {
    const curentUserRoles = getUserInfo()?.CurrentRolesId;
    const adminRole = '6BDFA417-0658-451A-BFB2-B5002828A1D9';

    if (curentUserRoles?.includes(',')) {
        const rolesArray = curentUserRoles?.split(',');

        if (rolesArray?.includes(adminRole)) {
            return true;
        } else {
            return false;
        }
    } else {
        if (curentUserRoles === adminRole) {
            return true;
        } else {
            return false;
        }
    }
};
export const cleanfield = (str, type = '') => {
    if (type == 'number') {
        str = str.replace(/[0-9]/g, '');
    }
    if (type == 'character') {
        str = str.replace(/[^0-9]/g, '');
    }
    if (type == 'numdigit') {
        str = str.replace(/[^0-9.]/g, '');

        // Allow only one decimal point
        const parts = str.split('.');
        if (parts.length > 2) {
            str = parts[0] + '.' + parts[1]; // Keep only the first decimal point
        }
    }
    return str;
}

export const allLetter = (inputtext) => {
    let letters = /^[A-Za-z]+$/;
    if (inputtext.value.match(letters)) {
        return true
    } else {
        return false
    }
}

// export const getMusicianPermission = (Id) => {
//     let MusicianPermissions = JSON.parse(getUserInfo()?.MusicianPermission);
//     return Object.values(MusicianPermissions).find(permission => permission['PermissionId'] === Id);
// }

export const getMusicianPermission = (Id) => {
    let musicianPermissions = getUserInfo()?.MusicianPermission;

    // Check if musicianPermissions is defined and not empty
    if (musicianPermissions) {
        try {
            let MusicianPermissions = JSON.parse(musicianPermissions);
            return Object.values(MusicianPermissions).find(permission => permission['PermissionId'] === Id);
        } catch (error) {
            // console.error('Error parsing MusicianPermission:', error);
            return null; // or handle the error as needed
        }
    } else {
        return null; // Handle case where musicianPermissions is undefined or empty
    }
}


//this is for suspended or expelled musician status
export const getStatus = (musician) => {
    let status = musician !== null ? musician?.UsersMusicianStatus : getUserInfo()?.UsersMusicianStatus;
    let SuspendedDays = musician !== null ? musician?.SuspendedDays : getUserInfo()?.SuspendedDays;
    if (status === undefined) {
        return '';
    }
    // console.log('getUserInfo()----', status);
    if (status.includes('Sus ')) {
        return 'suspended';
    } else if (status.includes('Exp ')) {
        return (SuspendedDays < 365 ? 'level 1' : (SuspendedDays > 365 && SuspendedDays < 1460 ? 'level 2' : 'level 3'));
    }
    return status;
}
export const cleanObject = (obj) => {
    for (var propName in obj) {
        if (!obj[propName]) {
            delete obj[propName];
        }
    }
    return obj
}
export const logout = () => {
    localStorage.removeItem(adminTokenName);
    notify("Logged out successfully!", 'success');
}
export const webPermissions = {
    // There will be a fixed number of permission in App. permission count could be 1K does't matter,  ( This is my permission )
    mastersModule: "D13D0BB6-CAD3-4B07-B80A-73A4FC3A270B",
    permissionsModule: "534EF7AB-058C-4C2F-BB6D-82F629C280C9",
    softDeleteModule: "DE1D5B4D-1359-4C97-A554-C2B753FC61F4",
    settingModule: "500571A4-22AE-4590-93CC-6715364EFEE8",
    dashboard: "B7D20C2A-19A2-44C2-BCB1-5D5F328AAEC4",
    //Users 
    usersView: "286FA462-3688-43A0-A1DA-141D32A22C4B",
    usersEdit: "A78E0271-BA7B-474B-878B-C6CF71502972",
    usersAdd: "B9BA9192-AB23-48F1-893A-B952A52B30E6"

}
export const uppercaseFirstLetter = (string) => {
    return string && string[0].toUpperCase() + string.slice(1);
}
export const lowercaseFirstLetter = (string) => {
    return string && string[0].toLowerCase() + string.slice(1);
}
export const resetForm = () => {
    document.getElementById("crud-form").reset();
    // console.log(document.getElementsByClassName("btn-close"))
    document.getElementsByClassName("btn-close")[1].click();
}
export const resetFormByID = (formID, modalID = null) => { //default null   
    document.getElementById(formID).reset();
    modalID && document.getElementsByClassName(modalID).click();
}
export const convertToUSD = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(number)
}
export const convertToUSDWithoutSign = (number) => {
    const amount = parseFloat(number);
    return amount.toLocaleString('en-US', {
        useGrouping: true,         // To add a comma as a thousand separator
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getDurationFormNow = (duration) => {

    if (duration === '6 Month') {
        let todayDate = new Date().toISOString().slice(0, 10);
        let d = new Date(todayDate);
        d.setMonth(d.getMonth() + 6);
        return d.toISOString().slice(0, 10);
    } else {
        let date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        return date.toISOString().slice(0, 10);
    }
}
export const generatePassword = () => {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 12;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
}
export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}
export const webErrors = {
    unSupportedFileError: 'Only PDF, JPG, PNG, DOC allowed!',
    inValidPhoneNumberError: 'Please enter a valid phone number!',
    invalidSsnNumberError: 'Please enter a 9 digit Valid SSN number!',
    inValidEmailError: 'Please enter a valid email address!',
    invalidPasswordError: 'Password should be of minimum 12 characters. At least 1 number, both lower and upercase letters and special characters ',
    passwordMismatch: 'Both password should match',
    enterCurrentPassword: 'Please enter your current password',
    currentPasswordSameAsNewPassword: 'New password should not be a old password!',
    ssnMismatch: 'Confirm ssn should match!',
    cardNumberLengthError: 'Please enter a 16 digit valid card number!',
    cvvLengthError: 'Please enter a valid CVV number!',
    // cvvLengthError: 'Please enter a valid 3 digit CVV number!',
    termsAndConditionError: 'You must accept terms & condition',
    TotalPayableAmtError: 'Please enter a payable amount!'
}
export const saveAndNextButton = (ID) => {

    console.log(document.getElementById(ID));

    document.getElementById(ID).click()
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}
export const togglePassword = (inputID) => {
    let x = document.getElementById(inputID);
    if (x.type === "password") {
        x.type = "text";
        return 'fa-eye-slash'
    } else {
        x.type = "password";
        return 'fa-eye'
    }
    x.classList.toggle('fa-eye-slash')
    // <i className="far fa-eye-slash"></i>
    // <i className="far fa-eye"></i>
}
export const isEditPage = window.location.pathname.includes('/edit/');
export const mask = (str, lastX = -4, maskChar = "#") => str?.toString().slice(lastX).padStart(str.length, maskChar)

export const isValidURL = (str) => {
    let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
        return false;
    } else {
        return true;
    }
}
export const validateFile = async (e) => {

    let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'doc'];

    if (e.target.files[0].type === '' && allowedExtension.indexOf(getFileExtension(e.target.files[0].type)) === -1) {
        document.getElementById(e.target.name).value = '';
        return { msg: webErrors.unSupportedFileError, error: true }

    } else if (allowedExtension.indexOf(e.target.files[0].type) === -1) {
        document.getElementById(e.target.name).value = '';
        return { msg: webErrors.unSupportedFileError, error: true }

    } else {
        if (e.target.files[0].size > 52428800) {
            document.getElementById(e.target.name).value = '';
            return { msg: 'File should be less than 50 MB!', error: true }
        }
        return { feildName: e.target.name, file: e.target.files[0], error: false }
    }
}

export const formatField = (input, type) => {
    var cleaned = ('' + input).replace(/\D/g, '');
    switch (type) {
        case 'SSN':
            var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2] + '-' + match[3];
            } else {
                return cleaned;
            }
            break;
        case 'zipcode':
            var match = cleaned.match(/^(\d{5})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2];
            } else {
                return cleaned;
            }
            break;
        case 'phone':
            // var match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            } else {
                return cleaned;
            }
            break;
        // var x = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        //     return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        // break
        case 'empPhone':
            // var match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/) || cleaned.match(/^\d{3}\d{3}\d{4}$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            } else {
                return cleaned;
            }
            break;

        default:
            break;
    }

    return input;
}

export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    // if (isNaN(birthDate)) {
    //     console.error('Invalid date string:', dateString);
    //     return null; // Or throw an error
    // }
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const getExportUrl = (string) => {
    return Host + 'Export/' + string;
}

export const styles = {

    largeIcon: {
        width: 25,
        height: 25,
        color: "#2f4ab9",
        cursor: "pointer"
    },

};

export const getInitials = (name) => {
    let initials;
    const nameSplit = name;
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials = name.substring(0, 1);
    } else if (nameLength === 1) {
        initials = name.substring(0, 1);
    } else return;

    return initials.toUpperCase();
}

export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name = name.length > 0 ? getInitials(name) : 'A';

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)

    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Roboto`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};

export const getRandomColor = () => {
    let status = getStatus(null);
    let color = status === 'suspended' ? '#aeae2f' : (status === 'level 1' || status === 'level 2' || status === 'level 3') ? '#ff0000' : '#078407';

    // var letters = '0123456789ABCDEF';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // console.log('status', color);
    return color;
}
//old permission 
export const checkPermission = (WebPermissionControlName) => {

    for (var i = 0; i < getUserInfo()?.permission.length; i++) {

        if (getUserInfo()?.permission.includes(WebPermissionControlName)) {
            return true;
        } else {
            return false
        }

    }
}
// new permission
export const newCheckPermission = (WebPermissionControlName) => {
    const permission = getUserInfo()?.permission;
    if (permission) {
        const userPermissions = JSON.parse(permission) || [];
        for (var i = 0; i < userPermissions.length; i++) {
            if (userPermissions[i]?.WebPermissionControlName === WebPermissionControlName) {
                return true;
            }
        }
    }
    return false;
}

export const sidebarCheckPermission = (WebPermissionPageName, WebPermissionControlName, WebPermissionControlType) => {
    const permission = getUserInfo()?.permission;
    if (permission) {
        const userPermissions = JSON.parse(permission);
        for (var i = 0; i < userPermissions.length; i++) {
            if (
                userPermissions[i]?.WebPermissionPageName === WebPermissionPageName &&
                userPermissions[i]?.WebPermissionControlName === WebPermissionControlName &&
                userPermissions[i]?.WebPermissionControlType === WebPermissionControlType
            ) {
                return true;
            }
        }
    }
    return false;
}
export const musicianStatusWisePermission = (Id) => {
    const permission = getUserInfo()?.MusicianPermission;
    if (permission) {
        const musicianPermissions = JSON.parse(permission) || [];
        for (var i = 0; i < musicianPermissions.length; i++) {
            if (musicianPermissions[i]?.PermissionId === Id && musicianPermissions[i]?.Flag == 1) {
                return true;
            }
        }
    }
    return false;
}
export const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
    }
}

export const addWorkDays = (startDate, days) => {
    if (isNaN(days)) {
        // console.log("Value provided for \"days\" was not a number");
        return
    }
    if (!(startDate instanceof Date)) {
        // console.log("Value provided for \"startDate\" was not a Date object");
        return
    }
    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);
    // If the current day is Sunday add one day
    if (dow == 0)
        daysToAdd++;
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
        //Subtract days in current working week from work days
        var remainingWorkDays = daysToAdd - (5 - dow);
        //Add current working week's weekend
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
            //Add two days for each working week by calculating how many weeks are included
            daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
            //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
            if (remainingWorkDays % 5 == 0)
                daysToAdd -= 2;
        }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
}

export const getCurrentDate = (startDate = new Date()) => {
    var today = new Date(startDate);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

export const updateCheckedProperty = (arr, targetId) => {
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        if (typeof obj === "object") {
            if (obj.value === targetId) {
                obj.checked = true;
            } else if (Array.isArray(obj.children)) {
                updateCheckedProperty(obj.children, targetId);
            }
        }
    }
    return arr;
}

export function getBrowserName() {
    const result = bowser.getParser(window.navigator.userAgent);
    return result.parsedResult;
}

export function GetCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "Visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "AMEX";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "Visa Electron";

    return "";
}
export const cardTypeMessages = {
    Visa: "Visa card",
    Mastercard: "Mastercard",
    AMEX: "American Express card",
    Discover: "Discover card",
    Diners: "Diners card",
    "Diners - Carte Blanche": "Diners - Carte Blanche card",
    JCB: "JCB card",
    "Visa Electron": "Visa Electron card",
};
export const isValidExpiryMonth = (expiryDate) => {
    const expiryMonth = parseInt(expiryDate?.substring(0, 2), 10);
    if (!expiryDate || expiryDate.length !== expiryLength) {
        // If expiry date is not provided or doesn't match the expected length
        return false;
    }
    return (expiryMonth >= 1 && expiryMonth <= 12);
};

export const isAdminStaffRoles = () => {
    //first role musician and non musician
    const roles = currentUserRole?.split(',');
    return (roles?.includes('2ABDC9E2-1C03-4788-8DD2-26174D050E34') || roles?.includes('7EB4F3C6-0B68-43DD-A996-17D141A1C3BA')) && !newCheckPermission("update-jobs");
};

export const disableAutocomplete = () => {
    const formElements = document.querySelectorAll('#crud-form input, #crud-form select, #crud-form textarea');
    formElements.forEach(element => {
        element.setAttribute('autocomplete', 'off');
    });
};

export const utcToLocalDateFormat = (dt) => {
    if (!dt) return ""; // Handle empty or invalid input

    // Convert the UTC date-time string to a Date object (no timezone conversion needed)
    const utcDate = new Date(dt);

    // Extract the month, day, and year
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const year = utcDate.getUTCFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month}/${day}/${year}`;

    // Return the formatted date string
    return formattedDate;
};
export const utcToLocalShortDateFormat = (dt) => {
    if (!dt) return ""; // Handle empty or invalid input

    // Convert the UTC date-time string to a Date object
    const utcDate = new Date(dt + "T00:00:00Z");

    // Define an array for month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract the day, month, and year
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const month = monthNames[utcDate.getUTCMonth()];
    const year = utcDate.getUTCFullYear();

    // Format the date as DD -Mon- YYYY
    const formattedDate = `${day}-${month}-${year}`;

    // Return the formatted date string
    return formattedDate;
};

export const utcToLocalDateTime = (dt) => {
    // Check if the input date is valid
    if (!dt) {
        return ""; // Return a default message or handle as needed
    }

    // Determine if the time is present in the input date
    const hasTime = dt.includes(' ');

    // Append UTC to ensure proper Date object creation
    const dtWithTime = hasTime ? dt : dt + ' 00:00:00';

    // Convert the date-time string to a Date object
    const utcDate = new Date(dtWithTime + " UTC");

    // Check if the date object is valid
    if (isNaN(utcDate.getTime())) {
        return ""; // Handle invalid date format
    }

    // Get the local date-time components
    const day = utcDate.toLocaleString('en-US', { day: '2-digit' });
    const month = utcDate.toLocaleString('en-US', { month: '2-digit' }); // Use '2-digit' for numeric month
    const year = utcDate.getFullYear();

    // Get the time components (hours, minutes, seconds) only if time is present
    const hours = utcDate.getHours().toString().padStart(2, '0');
    const minutes = utcDate.getMinutes().toString().padStart(2, '0');
    const seconds = utcDate.getSeconds().toString().padStart(2, '0');

    // Format the date as DD-MM-YYYY
    const formattedDate = `${month}/${day}/${year}`;

    // If time is present in the original input, include it; otherwise, just return the date
    const formattedDateTime = hasTime ? `${formattedDate} ${hours}:${minutes}:${seconds}` : formattedDate;

    // Return the formatted date-time string
    return formattedDateTime;
};


export const utcToLocalShortDateTime = (dt) => {
    // Check if the input date is valid
    if (!dt) {
        return ""; // Return a default message or handle as needed
    }

    // Determine if the time is present in the input date
    const hasTime = dt.includes(' ');

    // Append UTC to ensure proper Date object creation
    const dtWithTime = hasTime ? dt : dt + ' 00:00:00';

    // Convert the date-time string to a Date object
    const utcDate = new Date(dtWithTime + " UTC");

    // Check if the date object is valid
    if (isNaN(utcDate.getTime())) {
        return ""; // Handle invalid date format
    }

    // Get the local date-time components
    const day = utcDate.toLocaleString('en-US', { day: '2-digit' });
    const month = utcDate.toLocaleString('en-US', { month: 'short' }); // 'short' gives 'Aug'
    const year = utcDate.getFullYear();

    // Get the time components (hours, minutes, seconds) only if time is present
    const hours = utcDate.getHours().toString().padStart(2, '0');
    const minutes = utcDate.getMinutes().toString().padStart(2, '0');
    const seconds = utcDate.getSeconds().toString().padStart(2, '0');

    // Format the date
    const formattedDate = `${day}-${month}-${year}`;

    // If time is present in the original input, include it; otherwise, just return the date
    const formattedDateTime = hasTime ? `${formattedDate} ${hours}:${minutes}:${seconds}` : formattedDate;

    // Return the formatted date-time string
    return formattedDateTime;
};

export const generateRandomId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`; // Example: "1631547891234-9876"
};











