import * as actionTypes from '../action-types/filters-types';

const initialState = {
    filters: []
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTERS:
            return { ...state, filters: action.payload.allFilters }
        case actionTypes.RESET_FILTERS:
            return { ...state, filters: {} }
        default:
            return state;
    }
}
export default filterReducer;