import * as actionTypes from '../action-types/musician-status-types'
const initialState = {
    statusId: null,
}
const musicianStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MUSICIAN_STATUS:
            return { ...state, statusId: action.payload }
        default:
            return state
    }
}
export default musicianStatusReducer
